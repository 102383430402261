module.exports = {
	defaultTitle: 'Emmanuel Lartey',
	logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
	author: 'Emmanuel Lartey',
	url: 'https://enalartey.com',
	legalName: 'Emmanuel Lartey',
	defaultDescription: 'I’m Emmanuel and I’m a Full-stack engineer!',
	socialLinks: {
		twitter: 'http://www.twitter.com/elartey',
		github: 'https://github.com/elartey',
		linkedin: 'https://www.linkedin.com/in/emmanuellartey/',
		instagram: '',
		youtube: '',
		google: '',
	},
	googleAnalyticsID: 'UA-144260416-1',
	themeColor: '#6b63ff',
	backgroundColor: '#6b63ff',
	social: {
		facebook: 'appId',
		twitter: '@elartey',
	},
	address: {
		city: 'City',
		region: 'Region',
		country: 'Country',
		zipCode: 'ZipCode',
	},
	contact: {
		email: 'email',
		phone: 'phone number',
	},
	foundingDate: '2019',
	recaptcha_key: '6Lcvxq4UAAAAAOvJjek7e0C54Fg4HbVAOV2nzZBC',
}
